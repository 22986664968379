/** IMPORTS **/
@import "_variables.scss";

/* Adjust bootstrap variables */
$primary: $primary-blue;
$secondary: $secondary-gray;
$success: $complementary-green;
$info: $info-blue;
$warning: $warning-color;
$enable-negative-margins: true;

@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons";
@import "@ng-select/ng-select/themes/default.theme.css";

@import "mixins";

/* Import styles */
@import "backgrounds";
@import "badges";
@import "borders";
@import "buttons";
@import "cards";
@import "fonts";
@import "footer";
@import "forms";
@import "header";
@import "nav";
@import "tables";


/** MAIN styles **/

html {
    position: relative;
    min-height: 100%;
    color: $text-color;
    background-color: $background-color;
    scroll-behavior: smooth !important;
}

body {
    background-color: $background-color;
    padding-top: 0 !important;
    font-family: $base-font-family !important;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    color: $text-color;
    line-height: 1.5;
}

#content {
    margin-bottom: 360px;
}

/* sm + md: Small & medium devices (phones and tablets, less than 992px)*/
@media (min-width: 341px) and (max-width: 991px) {
    #content {
        margin-bottom: 460px;
    }
}

/* xxs: Extra Extra small devices (small portrait phones, less than 340px)*/
@media (max-width: 340px) {
    #content {
        margin-bottom: 480px;
    }
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

.clear {
    clear: both;
}

.hidden {
    display: none;
}

.clickable {
    cursor: pointer;
}

.form-control, .input-group>.form-control, .input-group-text,
.card, .card-header, .nav-pills .nav-link {
    border-radius: 0 !important;
}

.alert { padding: .5rem 1rem; }
.alert.alert-dismissible .btn-close { padding: .75rem 1rem; }

/* Tooltips */
.tooltip {
    &.bs-tooltip-bottom,&.bs-tooltip-top {
        margin: 4px 0 !important;
    }
    &.bs-tooltip-start,&.bs-tooltip-end {
        margin: 0 4px !important;
    }
    &.tooltip-sm {
        .tooltip-inner {
            font-size: .785rem;
        }
    }
    .tooltip-inner {
        background-color: rgba(0,0,0,0.8);
    }
    .tooltip-arrow {
        color: rgba(0,0,0,0.4);
    }
}

/* Print */
.only-for-printout {
    display: none;
}

@media print {
    #header, #header *, #footer, #footer *, #main-menu, #main-menu *, .current-dep, .nav, .btn {
        display: none;
    }

    .only-for-printout {
        display: inline-block;
    }

    .print-top-compressed {
        display: block;
        margin-top: -70px;
    }
    
    @page {
        margin: 2cm 1cm 1.5cm 1cm;
    }
}
