footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: left !important;
    margin-top: 4.5rem;
    padding: 3rem 0;
    background-color: $primary-blue;
    color: #FFF;
    font-weight: 300;
    font-size: 13px;

    h3 {
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 15px !important;
    }

    p, b, a {
        color: #fff;
        margin-bottom: 0;
    }
    p, a, a:hover {
        font-weight: 300;
    }

    #contact-info a:hover {
        color: $complementary-green-lightest;
        transition: .2s;
    }

    #links {
        &.socialmedia-row {
            border-top: 1px solid #0c5b75;
            padding: 3rem 0 0;
            margin-top: 3rem;
            text-align: center;
        }

        #powered-by {
            a {
                text-transform: uppercase;
                color: white !important;
            } 
            img {
                height: 25px;
            }
        }

        .socialmedia-btn {
            width: auto;
            position: relative;
            display: inline-block;
            text-transform: uppercase;
            margin: 0 0.75rem;

            &:hover {
                color: #fff !important;
                font-weight: 300 !important;

                .socialmedia-btn-icon {
                    transition: .2s;
                }
            }

            .socialmedia-btn-text {
                float: left;
                color: #fff;
                font-size: 13px;
                line-height: 36px;
                display: block;
            }
            .socialmedia-btn-icon {
                width: 36px;
                height: 36px;
                float: left;
                border-radius: 50%;
                background-color: #5c5750;
                position: relative;
                margin-right: 0.75rem;
                -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
                box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
                -webkit-transition: background-color .2s;
                transition: background-color .2s;
                text-align: center;
                padding: 8px 0;

                &:before {
                    margin: 8px 10px;
                }

                .fa {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    color: #fdf6ee;
                    font-size: 16px;
                }
            }
    
            &.facebook-btn:hover .socialmedia-btn-icon {
                background-color: #3b5998;
            }
            &.twitter-btn:hover .socialmedia-btn-icon {
                background-color: #0084b4;
            }
            &.linkedin-btn:hover .socialmedia-btn-icon {
                background-color: #3b5998;
            }
        }
    }
}
@media (max-width: 767px) {
    #links .socialmedia-btn .socialmedia-btn-text {
        display: none;
    }
}