@import "_variables";

.table {
    margin-bottom: 0;
}

th, th > span {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
}

.table th,
.table td {
    vertical-align: middle;
}

.table-sm {
    th:first-of-type,
    td:first-of-type {
        padding-left: .75rem;
    }
    th:last-of-type,
    td:last-of-type {
        padding-right: .75rem;
    }
}

.table-xs th,
.table-xs td { 
    padding: .15rem;
}

form-summary {
    dl dt h6 {
        margin: 0 !important;
        font-size: .85rem !important;
    }
}
