
.form-check-input:checked,
.form-check-input.is-invalid:checked,
.form-check-input[type="checkbox"]:indeterminate {
    background-color: $primary-blue-lighter;
    border-color: $primary-blue-lighter;
}

.radio-inline .form-check,
.form-check.first-check {
    display: inline-block !important;
    margin-right: 1rem;
    padding-top: calc(0.375rem + 1px);
}

formly-field.disabled {
    pointer-events: none !important;
}

select.form-select {
    border-radius: 0 !important;
}

.section-label {
    text-align: center;
    h6 { display: inline-block }
    span { color: $danger }
}

ngb-datepicker {
    .ngb-dp-week:not(.ngb-dp-weekdays) {
        background-color: white !important;
        border-radius: 0 !important;
    }
}

form.slim-form .horizontal-wrapper {
    margin-bottom: .5rem !important;
}

/* Validering nedan */

form.ng-touched.ng-invalid {
    input.form-control.ng-invalid:not(.ng-untouched),
    input.form-check-input.ng-invalid:not(.ng-untouched),
    formly-datepicker input.ng-invalid:not(.ng-untouched),
    ngb-timepicker.ng-invalid:not(.ng-untouched) input,
    .btn-group:has(input.ng-invalid:not(.ng-untouched)) label {
        border-color: #dc3545;
    }
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
    border-color: rgba(0, 0, 0, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: $offblack;
}

.invalid-feedback,
.warning-feedback {
    display: block;
    width: auto;
    font-weight: 500;
}

.warning-feedback {
    color: $warning-color;
    margin-top: .25rem;
    font-size: .875em;
}