/*#region Head-menu */
#fake-menu {
    background-color: $primary-blue;
    min-height: 30px;
}

#main-menu {
    width: 100%;
    background-color: $primary-blue;

    .row, ul {
        margin: 0 !important;
    }
}

.header-menu-item {
    display: inline-block;
    cursor: pointer;

    a {
        color: #fff !important;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        padding: .85rem 1.2rem .7rem;
        margin-right: .5rem;
        border-bottom: 4px solid $primary-blue;

        &.active {
            border-bottom: 4px solid $complementary-green-lightest;
        }

        &.menu-active,
        &[aria-expanded="true"] {
            background-color: $primary-blue-light;
            border-bottom: 4px solid $complementary-green-lightest;
        }

        &:hover {
            .chevron-right, 
            .chevron-down {
                border-color: $complementary-green-lightest;
                transition: .2s;
            }
        }

        &.dropdown-toggle::after {
            border: none !important;
        }

        .chevron-right {
            margin-bottom: 1px;
        }
        .chevron-right, 
        .chevron-down {
            border-color: #fff;
            height: .5rem;
            width: .5rem;
        }
    }
}

.menu-item {
    &.header-item {
        background-color: $background-color;
        color: $text-color;
        padding: .75rem 1rem .5rem;
        line-height: 16px;
        border-bottom: 1px solid #d6dfe4;

        &:first-of-type {
            border-top: 2px solid #d6dfe4;
        }
        &:last-of-type {
            border-bottom-width: 2px;
        }

        .bi-caret-right {
            margin-top: 2px;
        }
    }

    &:hover {
        .bi-caret-right {
            color: $complementary-green-lightest;
            transition: .2s;
        }
    }
}
/*#endregion*/
/*#region Dropdown-menu */
.dropdown-menu {
    z-index: 99999;
    min-width: 7.5rem;
    margin: -3px 0 0 !important;
    border-radius: 0 !important;
    border-width: 1px;
    background-color: $skyblue-light;;
            box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
       -moz-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);

    a {
        &:hover .bi-caret-right {
            color: $complementary-green-dark;
            transition: .2s;
        }
        &:first-child {
            border-top: 2px solid $skyblue-light-hover;
        }
        &:last-child {
            border-bottom-width: 2px;
        }
    }

    .menu-item {
        padding: .75rem 1rem !important;

        a {
            color: $primary-blue;
            text-transform: uppercase;
            font-size: 13px;

            .bi-caret-right {
                margin: 0 !important;
            }
        }
    }

    a.dropdown-item,
    button.dropdown-item  {
        padding: 7px 12px !important;
        color: $primary-blue;
        text-transform: uppercase;
        font-size: 13px;
        background-color: $white !important;
        border-bottom: 1px solid $skyblue-light-hover;
    }
}

.dropdown-toggle::after {
    margin-left: .35rem;
}
/*#endregion*/

/*#region Tabs */
ul.nav.nav-tabs .nav-link {
    border-radius: 0 !important;
    padding: .3rem .7rem;
    font-size: 16px;
    line-height: 31px;
    text-transform: uppercase;
    font-weight: 600;
    color: $info-blue-dark;

    &.active {
        background-color: $background-color;
        border-bottom-color: $background-color;
    }
}
/*#endregion*/

/*#region Nav-pills */
.nav-link.alert {
    border-radius: .25rem !important;
}
.nav-pills .nav-item .nav-link {
    margin-bottom: 2px;
}
.nav-pills .nav-link.active {
    background-color: $primary-blue;
}
.nav-pills .nav-link.active, .nav-pills .nav-link.active span {
    color: #fff !important;
}
/*#endregion*/

/*#region Accordion */
.accordion {
    .accordion-header .accordion-button, 
    .accordion-collapse .accordion-body {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    }

    .accordion-collapse.show .accordion-body {
        border-left: 4px solid $complementary-green;
    }
}
/*#endregion*/

/*#region Pagination*/
ul.pagination {
    justify-content: center !important;
    margin-top: 1rem;
    margin-bottom: 0;

    .page-item, .page-link {
        border-radius: 0 !important;
    }

    .page-item {
        display: flex;

        &:not(.active) .page-link {
            color: $primary-blue-lighter;
        }
    
        &.active .page-link {
            background-color: $primary-blue-lighter;
            border-color: $primary-blue-lighter;
        }

        .page-link {
            padding: .3rem .7rem;
            font-weight: 600;
            cursor: pointer;
        }
    }
}
/*#endregion*/

/*#region Breadcrumbs*/
.breadcrumb {
    li > a {
        text-transform: uppercase !important;
        font-size: 13px !important;
        font-weight: 600;

        &:hover {
            color: $complementary-green-dark;
            transition: .2s;
        }
    }
}
/*#endregion*/

/*#region Chevrons*/
.chevron {
    border-color: $primary-blue;
    border-style: solid;
    border-width: 0 0 2px 2px;
    content: '';
    display: inline-block;
    height: 0.75em;
    width: 0.75em;
    transition: .2s;
}

.chevron-sm {
    height: .5em;
    width: .5em;
}

.chevron-up {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    margin-bottom: -2px !important;
}

.chevron-right {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    margin-right: 5px !important;
}

.chevron-down {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    margin-bottom: 3px !important;
}

.chevron-left {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    margin-right: -5px !important;
}

.btn > .chevron {
    margin-bottom: 2px;
}

a:hover .chevron, button:hover .chevron {
    border-color: $complementary-green-lightest;
    transition: .2s;
}
/*#endregion*/

/*#region List Group */
.list-group {
    .list-group-item {
        color: $white !important;

        &.list-group-item-action {
            //font-weight: 600;
            background-color: $primary-blue;
            &.info { background-color: $info-blue-dark; }

            &:hover {
                transition: .2s;
                background-color: $primary-blue-dark;
                &.info { background-color: $info-blue; }
            }
        }

        & > .bi {
            display: flex;
            font-size: 21px;
        }
    }
}
/*#endregion*/