
@import "_variables";

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
}

.border-end {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 1rem;
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
}

.border-bottom-primary {
    border-bottom: 2px solid $primary-blue-light;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.border-start {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 1rem;
}
