@import "_font-import.scss";
@import "_variables.scss";

h1, h2, h3, h4, h5 {
    font-family: $header-font-family;
    font-weight: 400;
}

h6 {
    font-weight: 400;
}

h6, p, span, a, a:hover {
    font-family: $base-font-family !important;
    font-style: normal;
    font-weight: 400;
}

a, a:hover {
    font-weight: 400;
    text-decoration: none !important;
}

p.light {
    font-weight: 300;
}

b, strong, dt {
    font-weight: 600;
}

.small, small {
    font-size: 85% !important;
}

.form-control, .form-select {
    font-size: .85rem;
}


/* xs+sm: Extra small & Small devices (phones, less than 767px) */
@media (max-width: 767px) {
    h1 { font-size: 2.2rem; }
    h2 { font-size: 1.7rem; }
    h3 { font-size: 1.5rem; }
    h4 { font-size: 1.35rem; }
    h5 { font-size: 1.2rem; }
    h6 { font-size: 1rem; }
}
