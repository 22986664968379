
.btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600 !important;
    line-height: 19px;
    padding: 9px 14px;
    margin-top: 0;
    
    &:not(.rounded-circle) {
        border-radius: 0;
    }
    &.rounded-circle {
        line-height: .5rem !important;
    }
    &:hover {
        transition: .2s;
    }
    &:focus {
        box-shadow: none !important;
    }
    &:disabled {
        pointer-events: none !important;
    }
}

.btn-link, .nav-item .nav-link {
    text-transform: none;
    color: $primary-blue-light;
    &:hover, &:active {
        color: $primary-blue-dark !important;
    }
}

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-group-sm > .btn, .btn-sm {
    padding: .4rem .6rem;
    font-size: 13px;
    line-height: 17px;
}

.btn-group-xs > .btn, 
.btn.btn-xs {
    padding: .25rem .5rem .25rem !important;
}

.btn.rounded-circle {

    &:not(.btn-sm):not(.btn-xs) {
        height: 42px !important;
        width: 42px !important;
        padding: .45em !important;

        > .bi { margin: .45em !important }
        &.text-btn { padding: .65em !important }
    }

    > .bi {
        display: inline-block !important;
    }
}
.btn.btn-sm.rounded-circle {
    height: 32px !important;
    width: 32px !important;
    padding: .4em .3em !important;

    > .bi { margin: .3em !important }
    &.text-btn { padding: .5em !important }
}
.btn.btn-xs.rounded-circle {
    height: 25px !important;
    width: 25px !important;
    padding: .2em .15em !important;
    font-size: 95%;

    > .bi { margin: .2em !important }
    &.text-btn { padding: .35em !important }
}


.accordion-button:not(.collapsed) {
    background-color: $skyblue-light;
    color: $primary-blue;
}




/*
    button-variant(
        background, border, color,
        :hover-background, hover-border, hover-color,
        :active-background, active-border, active-color,
        :disabled-background, disabled-border, disabled-color
    );
*/

.btn-primary {
    @include button-variant(
        $primary-blue, $primary-blue, $white,
        $primary-blue-light, $primary-blue-light, $white,
        $primary-blue-light, $primary-blue-light, $white
    );
}
.btn-outline-primary {
    @include button-variant(
        $white, $primary-blue, $primary-blue,
        $lighter-gray, $primary-blue, $primary-blue,
        $lighter-gray, $primary-blue, $primary-blue,
        $secondary-gray, $primary-blue, $gray
    );
}
.btn-secondary {
    @include button-variant(
        $skyblue-light, $primary-blue-light, $primary-blue-dark,
        $skyblue-light-hover, $primary-blue-dark, $primary-blue-dark,
        $skyblue-light-hover, $primary-blue-dark, $primary-blue-dark
    );
}
.btn-outline-secondary {
    @include button-variant(
        transparent, $primary-blue-light, $primary-blue-dark,
        $skyblue-light, $primary-blue-light, $primary-blue-dark,
        $skyblue-light, $primary-blue-light, $primary-blue-dark
    );
}
.btn-skyblue {
    @include button-variant(
        $skyblue-lighter, $skyblue-light, $primary-blue,
        darken($skyblue-lighter, 5%), darken($skyblue-lighter, 15%), $primary-blue,
        darken($skyblue-lighter, 5%), darken($skyblue-lighter, 15%), $primary-blue,
        $disabled-gray, $primary-blue, $gray
    );
}
.btn-info {
    @include button-variant(
        $primary-blue-lighter, $primary-blue-lighter, $white,
        $primary-blue-light, $primary-blue-light, $white,
        $primary-blue-light, $primary-blue-light, $white
    );
}
.btn-outline-info {
    @include button-variant(
        transparent, $primary-blue-lighter, $primary-blue-lighter,
        $primary-blue-lighter, $primary-blue-lighter, $skyblue-lighter,
        $primary-blue-lighter, $primary-blue-lighter, $skyblue-lighter
    );
}
.btn-success {
    @include button-variant(
        $complementary-green, $complementary-green, $white,
        $complementary-green-light, $complementary-green-light, $white,
        $complementary-green-light, $complementary-green-light, $white,
        $complementary-green-disabled, $complementary-green-disabled, $white
    );
}
.btn-outline-success {
    @include button-variant(
        transparent, $complementary-green, $complementary-green,
        $complementary-green, $complementary-green, $white,
        $complementary-green, $complementary-green, $white
    );
}
.btn-warning {
    @include button-variant(
        $warning-color, $warning-color, $white,
        $warning-color-dark, $warning-color-dark, $white,
        $warning-color-dark, $warning-color-dark, $white
    );
}
.btn-outline-warning {
    @include button-variant(
        $white, $warning-color, $warning-color,
        $warning-color, $warning-color, $white,
        $warning-color, $warning-color, $white
    );
}