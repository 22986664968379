
@mixin crisp-image-rendering {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin centerer-horizontal {
    position: relative;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
}

@mixin breakpoint($from, $to: null) {
    @if ($from and $to) {
        $topx: $to - 1;
        @media (min-width: $from) and (max-width: $topx) {
            @content
        }
    }
    @else if ($from) {
        @media (min-width: $from) {
            @content
        }
    }
    @else {
        @content
    }                            
}
