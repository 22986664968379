
.card {
    margin-bottom: 1rem;

    .card-header {
        background-color: $skyblue-light;

        h4, h5, h6 {
            margin: 0 !important;
        }
    }
}

.card, .card-header, .nav-pills .nav-link {
    border-color: $skyblue-light-hover;
}
