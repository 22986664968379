
/**  LORA  **/

@font-face {
  font-family: "Lora";
  src: local("Lora"), local("lora-regular"), url("../fonts/lora/lora-regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lora";
  src: local("Lora Italic"), local("lora-italic"), url("../fonts/lora/lora-italic.ttf") format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Lora";
  src: local("Lora 700"), local("lora-700"), url("../fonts/lora/lora-700.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lora";
  src: local("Lora 700 Italic"), local("lora-700-italic"), url("../fonts/lora/lora-700-italic.ttf") format('truetype');
  font-weight: 700;
  font-style: italic;
}


/**  OPEN SANS  **/

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), local("open-sans-regular"), url("../fonts/open-sans/open-sans-regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Italic"), local("open-sans-italic"), url("../fonts/open-sans/open-sans-italic.ttf") format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Thin"), local("open-sans-300"), url("../fonts/open-sans/open-sans-300.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans Thin Italic"), local("open-sans-300-italic"), url("../fonts/open-sans/open-sans-300-italic.ttf") format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 600"), local("open-sans-600"), url("../fonts/open-sans/open-sans-600.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 600 Italic"), local("open-sans-600-italic"), url("../fonts/open-sans/open-sans-600-italic.ttf") format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 700"), local("open-sans-700"), url("../fonts/open-sans/open-sans-700.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 700 Italic"), local("open-sans-700-italic"), url("../fonts/open-sans/open-sans-700-italic.ttf") format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 800"), local("open-sans-800"), url("../fonts/open-sans/open-sans-800.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans 800 Italic"), local("open-sans-800-italic"), url("../fonts/open-sans/open-sans-800-italic.ttf") format('truetype');
  font-weight: 800;
  font-style: italic;
}
