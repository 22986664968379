
$base-font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
$header-font-family: "Lora", Georgia, serif;

$background-color: #f7fbfc;
$text-color: #333;

// SIR Colors

$primary-blue: #03465c;
$primary-blue-darker: #03465c;  //PRIMARY
$primary-blue-dark: #0c5b75;
$primary-blue-light: #347e96;
$primary-blue-lighter: #599db3; //INFO
$info-blue: #599db3;
$info-blue-dark: #347e96;

$complementary-green: #3ab463;
$complementary-green-darker: #006c25;
$complementary-green-dark: #0a8935;
$complementary-green-light: #1aa94b;
$complementary-green-lighter: #3ab463; //PRIMARY
$complementary-green-lightest: #60c983; 
$complementary-green-disabled: #99dcb0;

$complementary-red: #941700;
$complementary-red-darker: #941700;  //PRIMARY
$complementary-red-dark: #bb280d;
$complementary-red-light: #e74224;
$complementary-red-lighter: #f6694f;
$complementary-red-lightest: #ff8e79;

$black: #000;
$offblack: #333;
$offgray-dark: #5c5750;
$offgray-light: #aca6a0;
$offgray-lighter: #cbc6c1;

$gray: #a2a09e;
$light-gray: #cecece;
$lighter-gray: #f2f2f2;
$secondary-gray: #e2e2e2;
$disabled-gray: #e2e2e2;
$border-color: #ddd;

$white: #fff;
$offwhite: #fdf6ee;

$skyblue-light-hover: #d6dfe4;
$skyblue-light: #e5ebee;
$skyblue-lighter: #f7fbfc;

$bootstrap-danger: #dc3545;

$warning-color: #ff9246;
$warning-color-dark: #ef863c; 



$breakpoints: ( small: 0, smaller: 640px, medium: 768px, large: 1024px, larger: 1100px, xlarge: 1200px, xxlarge: 1440px, );