
.bg-skyblue-light {
    background-color: $skyblue-light;
}
.bg-skyblue-lighter {
    background-color: $skyblue-lighter;
}
.bg-skyblue-dark {
    background-color: $skyblue-light-hover;
}
