.badge {
    font-weight: 500;
    border-radius: 1rem !important;
}

.badge.rounded-circle {
    height: 42px !important;
    width: 42px !important;
    padding: 0 !important;

    > svg {
        margin: .35em !important;
    }
}

.badge.badge-sm.rounded-circle {
    height: 32px !important;
    width: 32px !important;
    padding: 0 !important;

    > svg {
        margin: .3em !important;
    }
}

.badge-primary {
    background-color: $primary-blue;
    border-color: $primary-blue;
    color: $white;
}

.badge-info {
    background-color: $info-blue;
    border-color: $info-blue;
    color: $white;
}

.badge-success {
    background-color: $complementary-green-light;
    border-color: $complementary-green-light;
    color: $white;
}

.badge-gray {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $offblack;
}