header {
    display: block !important;
    min-height: 148px;
    background-color: $background-color;

    #logo img {
        height: 120px;
        position: relative;
        @include crisp-image-rendering;
    }

    #logo-column {
        height: 125px;
    }

    .dropdown-menu {
        border-top: none;
    }
}

/* xs: Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
    #logo-column, #logo img 
    { height: 85px !important; }
}

/* sm + md: Small & Medium devices (landscape phones & tablets, 576px up to 991px) */
@media (min-width: 576px) and (max-width: 991px) {
    #header 
    { min-height: 110px; }
    #logo-column, #logo img 
    { height: 100px !important; }
}

.usability-menu {
    padding: 2px;
    margin-bottom: 0;
    border-bottom: 1px solid $primary-blue-light;
    float: right;

    li {
        overflow: hidden;
        display: inline-block;
        text-align: left;

        a {
            text-transform: uppercase;
            padding: .75rem;
            font-size: 12px;
            color: $primary-blue;
            font-weight: 600;

            .chevron-right {
                border-color: $primary-blue;
                margin-right: 2px !important;
            }

            &:hover {
                i {
                    transition: .2s;
                    border-color: $complementary-green-dark;
                }

                svg {
                    transition: .2s;
                    color: $complementary-green-dark;
                }
            }
        }

        .dropdown-menu > .dropdown-item > .bi-caret-right {
            margin-top: 2px;
        }

        &:first-child > a {
            padding: .75rem .75rem .75rem 0;
        }
        &:last-child > a {
            padding: .75rem 0 .75rem .75rem;
        }
    }

    .dropdown-menu {
        margin-top: -1px;
        margin-left: -2px;
        border-color: $primary-blue;
    }
}